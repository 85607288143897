// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("HMR")
	});
}

import "../css/app.css";

document.addEventListener("DOMContentLoaded", () => {
    const mainMenuItems = document.getElementById('mainMenuItems-mobile');
    const mobileMenuToggle = document.getElementById('mobile-menu-toggle');
    const mainBlockWrapper = document.getElementById('main-block-wrapper');
    const mobileMenuClose = document.getElementById('mobile-menu-close');

    console.log('mainMenuItems', mainMenuItems);
    console.log('mobileMenuToggle', mobileMenuToggle);
    console.log('mainBlockWrapper', mainBlockWrapper);

    if (mainMenuItems && mobileMenuToggle) {
        mobileMenuToggle.addEventListener('click', () => {
            console.log("mobile menu clicked");
            mainMenuItems.classList.toggle('hidden');
            mainMenuItems.classList.toggle('flex');
            mainBlockWrapper.classList.toggle('hidden');
        });
    }

    if (mobileMenuClose && mainMenuItems) {
        mobileMenuClose.addEventListener('click', () => {
            mainMenuItems.classList.toggle('hidden');
            mainMenuItems.classList.toggle('flex');
            mainBlockWrapper.classList.toggle('hidden');
        });
    }

    /**
     * Animation for homepage banner down arrow
     */
    document.getElementById('scrollAnchor').addEventListener('click', function(event) {
        event.preventDefault();
        const svgElement = document.querySelector('#scrollAnchor svg');
        svgElement.classList.add('no-bounce');
        window.scrollBy({
            top: window.innerHeight * 0.25,
            behavior: 'smooth'
        });
    });
})
